import React from 'react';
import Image from '../../image';
import {
    BlogDetailsWrap,
    FullwidthBox, TextPara} from './content.stc'
import Heading from '../../shared/heading'
import { Controller, Scene } from 'react-scrollmagic';

const ContentBlog = ({infos, title}) => {
    return(
        <>
        {
            infos && infos.map((elts,i) => (
                <div key={elts.id} className="project-features">
                        <BlogDetailsWrap>
                                <div className="row section-blog-xl">
                                    {
                                        elts.titre && 
                                        <div className="col-3 offset-1">
                                            <div className="rn-project-meta-inner">
                                                <div className="section-title">
                                                    <Heading {...title}>{elts.titre}</Heading>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        elts.sousTitre &&
                                        <div className="col-3 offset-1">
                                            <div className="rn-project-meta-inner">
                                                <div className="section-title">
                                                    <h4 style={{ color: 'black' }}>{elts.sousTitre}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        elts.texte &&
                                        <div className="col-4 offset-1">
                                            <div className="rn-project-content">
                                                {elts.texte.map((ft, i) => <TextPara key={`ft-${i}`}>{ft}</TextPara>)}
                                            </div>
                                        </div>
                                    }
                                </div>
                        </BlogDetailsWrap>
                        {
                            elts.image && 
                            <FullwidthBox>
                                <div className="row">
                                    <div className="col-xl-4">
                                        <Controller>
                                            <Scene classToggle="animated" triggerElement={`#${elts.id}`} triggerHook="onCenter">
                                                <div className="rn_surface" id={elts.id}>
                                                    <Image fluid={elts.image.childImageSharp.fluid} alt="single"/>
                                                </div>
                                            </Scene>
                                        </Controller>
                                    </div>
                                </div>
                            </FullwidthBox>
                        }
                    </div>
            )) 
        }
        </>
    )
}

export default ContentBlog;