import styled from 'styled-components';
import {
    device
} from '../../../theme'

export const TextPara = styled.p `
    text-align: justify;
`

export const BlogDetailsWrap = styled.div `
    background: #f8f8f8;
    .section-blog-xl {
        padding: 40px 0;
        @media ${device.medium}{
            padding: 30px 0;
        }
        @media ${device.small}{
            padding: 10vw 0;
        }
    }
    .col-3 {
        @media ${device.xlarge}{
            margin-left: 20vw;
        }
        @media ${device.small}{
            width: 80vw;
            margin-left: 10vw;
        }
    }

`;

export const BlogFeatureWrap = styled.div `
    .rn-project-meta-inner{
        margin-top: -10px;
    }
    .rn-project-content {
        margin-top: -7px;
        margin-bottom: -18px;
    }
`;

export const BannerWrap = styled.div `
    .gatsby-image-wrapper{
        z-index: -1;
    }
`;

export const ProjectHeaderMeta = styled.div `
    margin-top: 80px;
    @media ${device.large}{
        margin-top: 100px;
    }
    @media ${device.small}{
        margin-top: 5px;
        margin-bottom: 38px;
    }
`;

export const ProjectType = styled.div `
    margin-top: 30px;
`;


export const FullwidthBox = styled.div `
    padding-left: 10vw;
    padding-right: 10vw;
`;